let region = 'us-west-2';
let CognitoUserPoolId = 'us-west-2_0FSk5pQLs';
let CognitoClientId = '4sfbqhb3nv11f7k537f6sc0nes';
let CognitoIdentityPoolId = 'us-west-2:d07c5038-e564-4446-9e38-0eccd14bb4c6';
let AddPackagesEndpoint = 'https://3icmzonjh6.execute-api.us-west-2.amazonaws.com/default/d31AlphaAddPackagesToTrack';
let UpdateOrderInfoEndpoint = 'https://4kz5lffseg.execute-api.us-west-2.amazonaws.com/default/d31AlphaUpdateOrderInfo';
let SendNotificationEndpoint = 'https://kiiurcgq80.execute-api.us-west-2.amazonaws.com/default/d31AlphaSendNotifications';
let SaveSettingsEndpoint = 'https://nayymid9o5.execute-api.us-west-2.amazonaws.com/default/d31AlphaUpdateSettingsInfo';
let DbLoadGranularityWrite = 5;
let DbLoadGranularityRead = 10;
let stage = "alpha";

let url = window.location.href;
if (url.includes("d31.titantools.org")) {
  region = "ap-northeast-2";
  CognitoUserPoolId = 'ap-northeast-2_KP7RuDbuX';
  CognitoClientId = 'p7cs5idd3999fb8j2c67ahadj';
  CognitoIdentityPoolId = 'ap-northeast-2:24b1689d-9ec7-4dff-a404-0e3a0bd58af7';
  AddPackagesEndpoint = 'https://6beeggx8ee.execute-api.ap-northeast-2.amazonaws.com/default/d31ProdAddPackagesToTrack';
  UpdateOrderInfoEndpoint = 'https://mvbuf1l6d0.execute-api.ap-northeast-2.amazonaws.com/default/d31ProdUpdateOrderInfo';
  SendNotificationEndpoint = 'https://bt2te843e9.execute-api.ap-northeast-2.amazonaws.com/default/d31ProdSendNotifications';
  SaveSettingsEndpoint = 'https://v7fgaqymqc.execute-api.ap-northeast-2.amazonaws.com/default/d31ProdUpdateSettingsInfo';
  stage = "prod";
}

export default {
    region,
    CognitoUserPoolId,
    CognitoClientId,
    CognitoIdentityPoolId,
    AddPackagesEndpoint,
    UpdateOrderInfoEndpoint,
    SendNotificationEndpoint,
    SaveSettingsEndpoint,
    DbLoadGranularityWrite,
    DbLoadGranularityRead,
    stage,
  }