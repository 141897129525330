<template>
<div class="jumbotron d-flex align-items-center min-vh-100" style="
        background-image: url(img/theme/package.jpg);
        background-size: cover;">

  <span class="mask bg-gradient-gray opacity-3"></span>
  <div class="container text-center">
    <div class="col d-flex justify-content-center">
        <div class="card bg-secondary shadow border-0">
          <div class="card-body px-lg-5 py-lg-5">
            <div class="text-center mb-4">
              <h1>Vianacare</h1>
            </div>

            <form
              class="navbar-search form-inline mr-3 d-none d-md-flex ml-lg-auto"
              @submit.prevent="search($event)"
            >
              <div class="form-group mb-0">
                <base-input
                  placeholder="order or tracking number"
                  class="input-group-alternative"
                  alternative=""
                  addon-right-icon="fas fa-search"
                  name="searchBox"
                >
                </base-input>
              </div>
            </form>

            <div class="text-center mb-5">
            </div>

            <div class="text-left" v-if="displaySearchResults">
              <stats-card
                title="2105/1457V"
                type="red"
                sub-title="Delay"
                icon="ni ni-notification-70"
                class="mb-4 mb-xl-0"
              >
                <template v-slot:footer>
                  <span class="text mr-2">
                    UPS
                  </span>
                  <span class="text-nowrap">
                    <a href="http://www.ups.com/track?loc=null&tracknum=1Z1A83A04205251615&requester=ST/trackdetails">
                    1Z1A83A04205251615
                    </a>
                  </span>
                </template>
              </stats-card>
            </div>

          </div>
        </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "ExternalOrderSearch",
  components: {
  },
  data() {
    return {
      displaySearchResults : false,
    }
  },
  methods : {
    search : async function(event) {
      let searchText = event.target.searchBox.value;
      this.displaySearchResults = true;
    }
  }
};
</script>