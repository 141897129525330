<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="D31"
      title="D31"
    >
      <template v-slot:links>
        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'ni ni-tv-2 text-primary',
            path: '/dashboard',
          }"
        />

        <sidebar-item
          :link="{
            name: 'Track Packages',
            icon: 'ni ni-delivery-fast text-yellow',
            path: '/packages',
          }"
        />

        <sidebar-item
          :link="{
            name: 'Notifications',
            icon: 'ni ni-email-83 text-pink',
            path: '/notifications',
          }"
        />

        <sidebar-item
          :link="{
            name: 'Add New',
            icon: 'ni ni-cart text-purple',
            path: '/add',
          }"
        />

        <sidebar-item
          :link="{
            name: 'Settings',
            icon: 'ni ni-settings text-green',
            path: '/settings',
          }"
        />
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <!-- your content here -->
        <router-view></router-view>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
  },
  data() {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
<style lang="scss"></style>
