<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-header bg-transparent pb-5">
          <div class="text-muted text-center mt-2 mb-3">
            <small>Sign in with</small>
          </div>
          <div class="btn-wrapper text-center">
            <a href="#" class="btn btn-neutral btn-icon">
              <span class="btn-inner--icon"
                ><img src="img/icons/common/github.svg"
              /></span>
              <span class="btn-inner--text">Github</span>
            </a>
            <a href="#" class="btn btn-neutral btn-icon">
              <span class="btn-inner--icon"
                ><img src="img/icons/common/google.svg"
              /></span>
              <span class="btn-inner--text">Google</span>
            </a>
          </div>
        </div>
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted mb-4">
            <small>Set new password</small>
          </div>
          <form role="form" @submit.prevent="signin">
              <base-input
                formClasses="input-group-alternative mb-3"
                placeholder="New Password"
                type="password"
                addon-left-icon="ni ni-lock-circle-open"
                v-model:value="newPassword"
                name="newPassword"
              >
            </base-input>

            <div class="text-center">
              <base-button native-type="submit" type="primary" class="my-4">Sign in</base-button>
            </div>
          </form>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <a href="#/forgot" class="text-light"><small>Forgot password?</small></a>
        </div>
        <div class="col-6 text-right">
          <router-link to="/register" class="text-light"
            ><small>Create new account</small></router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import auth from '../auth';
import router from '../router';


export default {
  name: "resetpw",
  data() {
    return {
      newPassword: ""
    };
  },
  methods: {
    signin: function(event) {
      var newPassword = event.target.elements.newPassword.value
      auth.completeNewPasswordChallenge(newPassword, function(err, result) {
          if (!err) {
            console.log("new pasword set successful");
            auth.setId(result.idToken, function(err) {
              if (!err){ 
                router.push("/");
              } else {
                console.log("unable to set Id", err);
              }
            });
          } else {
            console.log("new pasword set fail", err);
          }
      });
    },
  }
};
</script>
<style></style>
