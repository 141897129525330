import { createRouter, createWebHashHistory } from "vue-router";

import DashboardLayout from "@/layout/DashboardLayout";
import AuthLayout from "@/layout/AuthLayout";

import Dashboard from "../views/Dashboard.vue";
import Packages from "../views/Packages.vue";
import Notifications from "../views/Notifications.vue";
import Add from "../views/Add.vue";
import Settings from "../views/Settings.vue";
import Register from "../views/Register.vue";

import Login from "../views/Login.vue";
import Forgot from "../views/Forgot.vue";
import ResetPassword from "../views/ResetPassword.vue";
import ErrorPage from "../views/ErrorPage.vue";

import ExternalOrderSearch from "../views/ExternalOrderSearch.vue";

import auth from '../auth';
import store from "../store";

function requireAuth (to, from, next) {
  if (!store.getters.getUserSessionPromise) {
    next({
      path: '/logout',
      query: { redirect: to.fullPath }
    });
    return;
  }
  store.getters.getUserSessionPromise.then(session => {
      next();
    });
}

function requireNotAuth(to, from, next) {
  if (!store.getters.getUserSessionPromise) {
    next();
    return;
  }
  next("/");
}

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: DashboardLayout,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        beforeEnter: requireAuth,
        components: { default: Dashboard  },
      },
      {
        path: "/packages",
        name: "packages",
        beforeEnter: requireAuth,
        components: { default: Packages },
      },
      {
        path: "/notifications",
        name: "notifications",
        beforeEnter: requireAuth,
        components: { default: Notifications },
      },
      {
        path: "/add",
        name: "add",
        beforeEnter: requireAuth,
        components: { default: Add },
      },
      {
        path: "/settings",
        name: "settings",
        beforeEnter: requireAuth,
        components: { default: Settings },
      },
    ],
  },
  {
    path: "/",
    redirect: "login",
    component: AuthLayout,
    children: [
      {
        path: "/login",
        name: "login",
        beforeEnter: requireNotAuth,
        components: { default: Login },
      },
      /*{
        path: "/register",
        name: "register",
        beforeEnter: requireNotAuth,
        components: { default: Register },
      },*/
      {
        path: "/forgot",
        name: "forgot",
        beforeEnter: requireNotAuth,
        components: { default: Forgot },
      },
      /*{
        path: "/resetpw",
        name: "resetpw",
        beforeEnter: requireNotAuth,
        components: { default: ResetPassword },
      },
      {
        path: "/error",
        name: "error",
        components: { default: ErrorPage },
      },*/
    ],
  },
  {
    path: "/logout",
    beforeEnter: function(to, from, next) {
      if (!store.getters.getUserSessionPromise) {
        next({
          path: '/login',
          query: { redirect: to.fullPath }
        });
        return;
      }
      store.getters.getUserSessionPromise.then(session => {
          auth.userPool.getCurrentUser().signOut();
          auth.deinit();
          next({
            path: '/login',
            query: { redirect: to.fullPath }
          });
        });
    }
  },
  {
    path: "/ordersearch",
    component: ExternalOrderSearch,
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  linkActiveClass: "active",
  routes,
});

export default router;
