<template>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        min-height: 400px;
        background-image: url(img/theme/package.jpg);
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-purple opacity-7"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-7 col-md-10">
            <h1 class="display-2 text-white">Add orders</h1>
            <p class="text-white mt-0 mb-0">
              Each line is an order and you can enter multiple orders. 
              An order consists of order id, tracking number, carrier
              each separated by a space. After putting in your information,
              press preview. Then if data looks good, press submit.
              The results should appear in "status" and "status message"
              columns.
            </p>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-6 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">Add order info</h3>
                  </div>
                  <div class="col-4 text-right">
                    <base-button size="sm" 
                        @mouseup="preview()"
                    >Preview</base-button>
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-8">
                  </div>
                </div>
              </div>
            </template>

            <small>
              <input type="checkbox" v-model="checkedCarrier" id="carrier"
                @change="checkboxClicked">
              &nbsp;
              <label for="carrier">carrier</label>
              &nbsp;
              <input type="checkbox"  v-model="checkedCustomerEmail" id="customerEmail"
                @change="checkboxClicked">
              &nbsp;
              <label for="customerEmail">customer email</label>
              &nbsp;
              <input type="checkbox"  v-model="checkedCustomerName" id="customerName"
                @change="checkboxClicked">
              &nbsp;
              <label for="customerName">customer name</label>
            </small>

            <base-input alternative="">
              <textarea v-model="orders"
                rows="5"
                class="form-control form-control-alternative"
                v-bind:placeholder="inputFormPlaceholder"
                :key="inputOrdersUser"
                @change="textChange"
              >
              </textarea>
            </base-input>

            <div class="text-right">
              <base-button size="sm" 
                  type="info" 
                  @mouseup="submit()"
                  :disabled="submitDisabled"
                  title="must click preview first also again if text changed"
              >Submit</base-button>

            </div>
          </card>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-xl-12 order-xl-2 mb-5 mb-xl-0">
          <div class="card card-profile shadow">
            <div
              class="card-header border-0"
            >
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" >
                    Preview
                  </h3>
                  <!--small class="mb-0" >
                    {{ statusMessage }}
                  </small-->
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <base-table
                class="table align-items-center table-flush"
                :thead-classes="'thead-light'"
                tbody-classes="list"
                :data="ordersData"
              >
                <template v-slot:columns>
                  <th>Order ID</th>
                  <th>Tracking Number</th>
                  <th v-if="checkedCarrier">Carrier</th>
                  <th v-if="checkedCustomerEmail">Customer Email</th>
                  <th v-if="checkedCustomerName">Customer Name</th>
                  <th>Status</th>
                  <th>Status Message</th>
                  <th></th>
                </template>

                <template v-slot:default="row">
                  <th scope="row">                        
                    {{ row.item.orderId }}
                  </th>
                  <td>
                    {{ row.item.trackingNumber }}
                  </td>
                  <td v-if="checkedCarrier">
                    {{ row.item.carrier }}
                  </td>
                  <td v-if="checkedCustomerEmail">
                    {{ row.item.customerEmail }} 
                  </td>
                  <td v-if="checkedCustomerName">
                    {{ row.item.customerName }} 
                  </td>
                  <td>
                    <i class="ni ni-check-bold" v-if="row.item.status == true"></i>
                    <svg xmlns="http://www.w3.org/2000/svg" v-if="row.item.status == false" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                  </td>
                  <td>
                    {{ row.item.statusMessage }}
                  </td>
                </template>
              </base-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import config from '../config';
import axios from 'axios';

async function sendOrdersRequest(userSessionPromise, ordersParam) {
  var jwtToken = await userSessionPromise.then(session => {
    return session.credentials.idToken;
  })
  var ep = config.AddPackagesEndpoint;
  const headers = { 
    "Authorization": jwtToken
  };
  const orders = {
    'orders' : ordersParam
  }
  return axios.post(ep, orders, { headers })
    .then(response => { 
      return response.data;
    }).catch(err => {
      console.log("unable to send orders request", err);
      return err.response.status;
    })
}

function updateOrdersStatus(ordersData, res) {
  let ret = true;
  Object.keys(res).forEach(function(id) {
    ordersData.forEach(orderData => {          
      if (id == orderData.id) {            
        orderData.status = res[id].status;
        orderData.statusMessage = res[id].statusMessage;
        ret = ret && res[id].status;
      }
    })
  })

  return ret;
}

function placeHolderStr(carrier, customerName, customerEmail) {
  let orderStr = "orderId1   trackingNumber1   ";
  if (carrier) {
    orderStr += "carrierName1   ";
  }
  if (customerEmail) {
    orderStr += "customerEmail1   ";
  }
  if (customerName) {
    orderStr += "customerName1   ";
  }
  orderStr += "\norderId2   trackingNumber2   ";
  if (carrier) {
    orderStr += "carrierName2   ";
  }
  if (customerEmail) {
    orderStr += "customerEmail2   ";
  }
  if (customerName) {
    orderStr += "customerName2   ";
  }
  orderStr += "\n...";
  return orderStr;
}

function statusToText(status) {
  switch(status) {
    case "BEFORE_PREVIEW" : 
      return "please press preview after adding text";
    case "PREVIEW" : 
      return "please review then press submit";
    case "SUBMIT_WAIT" : 
      return "waiting for submit results";
    case "SUBMIT_COMPLETE" : 
      return "add or update complete";
    default : 
      return "system error";
  }
}

export default {
  name: "add",
  methods : {
    preview : async function() {
      this.status = "PREVIEW";
      this.ordersData = [];
      var inputOrders = this.orders;
      if (!inputOrders) {
        return;
      }
      var id = 0;
      inputOrders.split('\n').forEach(order => {
        if (order.length == 0) {
          this.ordersData.push({
            id : id,
            orderId : "",
            trackingNumber : "",
            carrier : "",
            status : false,
            statusMessage : "order Id empty",
          });
        } else {
          let split = order.split(/ |\t/).filter(function(e){return e});
          let orderId = split[0];
          let trackingNumber = "";
          let carrier = "";
          let customerName = "";
          let customerEmail = "";
          let statusMessage = "";
          let status = null;
          if (split.length >= 2) {
            trackingNumber = split[1];
          } else {
            status = false;
            statusMessage = "tracking number is missing";
          }
          // now properly parse the input string other than order and tracking number
          if (split.length >= 3) {
            let startIdx = 2;
            if (this.checkedCarrier) {
              carrier = split[startIdx];
              startIdx++;
            }
            if (this.checkedCustomerEmail) {
              customerEmail = split[startIdx];
              startIdx++;
            }
            if (this.checkedCustomerName) {
              customerName = split.slice(startIdx).join(" ");
              startIdx++;
            }
          }
          this.ordersData.push({
            id : id,
            orderId : orderId,
            trackingNumber : trackingNumber,
            carrier : carrier,
            customerEmail : customerEmail,
            customerName : customerName,
            status : status,
            statusMessage : statusMessage
          });
        }
        id++;
      })
      this.submitDisabled = false;
    },
    submit : async function() {
      this.status = "SUBMIT_WAIT";
      let inputOrders = this.orders;
      let ordersGroup = [];
      let id = 0;
      for (const order of inputOrders.split('\n')) {
        if (order.length == 0) {
          id++;
          continue;
        }

        let split = order.split(/ |\t/).filter(function(e){return e});
        let orderId = split[0];
        let trackingNumber = "";
        let carrier = null;
        let customerEmail = null;
        let customerName = null
        let sendOrder = true;
        // if less than 2, something wrong with input
        if (split.length >= 2) {
          trackingNumber = split[1];
        } else {
          sendOrder = false;
        }
        // has email or customer names
        if (split.length >= 3) {
          let startIdx = 2;
          if (this.checkedCarrier) {
            carrier = split[startIdx];
            startIdx++;
          }
          if (this.checkedCustomerEmail) {
            customerEmail = split[startIdx];
            if (customerEmail == null) {
              customerEmail = "";
            }
            startIdx++;
          }
          if (this.checkedCustomerName) {
            customerName = split.slice(startIdx).join(" ");
            if (customerName == null) {
              customerName = "";
            }
            startIdx++;
          }
        }

        if (!sendOrder) {
          id++;
          continue;
        }

        // adding order to send
        let newOrder = {
          id : id,
          orderId: orderId,
          trackingNumber: trackingNumber,
        }
        if (carrier != null) {
          newOrder.carrier = carrier;
        }
        if (customerName != null) {
          newOrder.customerName = customerName;
        }
        if (customerEmail != null) {
          newOrder.customerEmail = customerEmail;
        }
        ordersGroup.push(newOrder);

        if (ordersGroup.length < config.DbLoadGranularityWrite) {
          // can be sent as batch next time
          id++;
          continue;
        }

        // try to send data
        let retry = 0;
        while (retry < 3) {
          if (retry > 0) {
            await new Promise(resolve => setTimeout(resolve, (1 << retry) * 1000));
          }
          let res = await sendOrdersRequest(this.$store.getters.getUserSessionPromise, ordersGroup);
          if (typeof res == 'number') {
            // the request itself failed
            let statusMessage = "error code " + res + " system retrying";
            if (res == 500) {                      
              statusMessage = "internal server error retrying";
            } else if (res == 401) {
              statusMessage = "permission error, please refresh and try again";
            }
            for (let i = 0; i < ordersGroup.length; i++) {
              let id = ordersGroup[i].id;
              let orderData = this.ordersData[id];
              orderData.status = false;
              orderData.statusMessage = statusMessage;
            }
            retry++;
            continue;
          }
          let success = updateOrdersStatus(this.ordersData, res);
          if (success) {
            break;
          }
          retry++;
        }

        if (retry == 3) {
          // ultimately failed
          for (let i = 0; i < ordersGroup.length; i++) {
            let id = ordersGroup[i].id;
            let orderData = this.ordersData[id];
            orderData.status = false;
            orderData.statusMessage = "failed please contact hephaestus@titantools.org";
          }
          ordersGroup = [];
          break;
        }

        ordersGroup = [];
        id++;
      }
      if (ordersGroup.length > 0) {
        // this is a copy of above code
        // try to send data
        let retry = 0;
        while (retry < 3) {
          if (retry > 0) {
            await new Promise(resolve => setTimeout(resolve, (1 << retry) * 1000));
          }
          let res = await sendOrdersRequest(this.$store.getters.getUserSessionPromise, ordersGroup);
          if (typeof res == 'number') {
            // the request itself failed
            let statusMessage = "error code " + res + " system retrying";
            if (res == 500) {                      
              statusMessage = "internal server error retrying";
            } else if (res == 401) {
              statusMessage = "permission error, please refresh and try again";
            }
            for (let i = 0; i < ordersGroup.length; i++) {
              let id = ordersGroup[i].id;
              let orderData = this.ordersData[id];
              orderData.status = false;
              orderData.statusMessage = statusMessage;
            }
            retry++;
            continue;
          }
          let success = updateOrdersStatus(this.ordersData, res);
          if (success) {
            break;
          }
          retry++;
        }

        if (retry == 3) {
          // ultimately failed
          for (let i = 0; i < ordersGroup.length; i++) {
            let id = ordersGroup[i].id;
            let orderData = this.ordersData[id];
            orderData.status = false;
            orderData.statusMessage = "failed please contact hephaestus@titantools.org";
          }
        }
      }
      this.status = "SUBMIT_COMPLETE";
    },
    checkboxClicked() {
      let orderStr = placeHolderStr(this.checkedCarrier, this.checkedCustomerName, this.checkedCustomerEmail);
      this.inputFormPlaceholder = orderStr;
      this.inputOrdersUser++;
      this.submitDisabled = true;
    },
    textChange() {
      if (this.submitDisabled == false) {
        this.submitDisabled = true;
      }
    }
  },
  data() {
    return {
      orders : "",
      submitDisabled : true,
      ordersData : [
      ],
      checkedCarrier : true,
      checkedCustomerName : false,
      checkedCustomerEmail : false,
      inputOrdersUser : 0,
      status : "BEFORE_PREVIEW", //BEFORE_PREVIEW, PREVIEW, SUBMIT_WAIT, SUBMIT_COMPLETE
    }
  }, 
  setup() {
    return {
      inputFormPlaceholder: placeHolderStr(true, false, false)
    }
  },
  computed : {
    /*statusMessage : function()  {
      return statusToText(this.status);
    }*/
  }
};
</script>
<style></style>
