import AWS from 'aws-sdk';

import store from '../store';
import config from '../config';
import axios from 'axios';

async function init(groupNamePromise, credentialsPromise) {
    let credentials = await credentialsPromise; // need this for dynamodb init
    let ddb = credentialsPromise.then(res => { return new AWS.DynamoDB() });
    store.commit("setDdbPromise", ddb);
}

function deinit() {
    store.commit("setDdbPromise", null);
}

function deliveryStatusToColor(status) {
  /*
  processing : default
  labeled : default
  in transit : info
  transit : info
  delivered : success
  clearance : warn
  delay : danger
  */
  switch(status) {
  case "processing" :
  case "labeled" :
    return "default";
  case "in transit" :
    return "info";
  case "transit" :
    return "info";
  case "delivered" :
    return "success";
  case "clearance" :
    return "warning";
  case "delay" :
    return "danger";
  default : 
    return "";
  }
}

var timeDisplayOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };

function utcTimeToLocal(s) {
  if (s.includes("+")) {
    return new Date(s).toLocaleString(undefined, timeDisplayOptions)
  }
  return new Date(s + "+0000").toLocaleString(undefined, timeDisplayOptions)
}

function itemToRow(element, id) {
  let trackingNumber = element.tracking_num.S;
  let carrier = element.carrier.S != null ? element.carrier.S.toLowerCase() : "";
  let carrierLink = "";
  let imgPath = "";
  if (carrier.includes("fedex")) {              
    imgPath = "img/companies/fedex.png"
    carrierLink = "https://www.fedex.com/fedextrack/?tracknumbers=" + trackingNumber;
  } else if (carrier.includes("ups")) {
    imgPath = "img/companies/ups.png"
    carrierLink = "https://www.ups.com/track?loc=null&tracknum=" + trackingNumber;
  }
  let lastUpdatedDate = utcTimeToLocal(element.update_time.S);
  let createdDate = utcTimeToLocal(element.create_time.S);
  let emailedDate = "";
  if (element.email_sent.S != "no") {
    emailedDate = new Date(element.email_sent.S).toLocaleTimeString(undefined, timeDisplayOptions);
  }

  let customerName = "";
  let customerEmail = "";
  if ('customer_name' in element) {
    customerName = element.customer_name.S;
  }
  if ('customer_email' in element) {
    customerEmail = element.customer_email.S;
  }

  let statusDetail = "";
  let status = element.delivery_status.S;

  if (status != "processing") {
    statusDetail = element.delivery_status_details.M.detailed_description.S;
  }

  return {
    id : id,
    img: imgPath,
    title: element.order_id.S,
    trackingNumber: trackingNumber,
    carrier : carrier,
    carrierLink : carrierLink,
    status: status,
    statusType: deliveryStatusToColor(element.delivery_status.S),
    lastUpdatedDate: lastUpdatedDate,
    lastUpdatedDateIsoFormat: element.update_time.S,
    createdDate: createdDate,
    createdDateIsoFormat: element.create_time.S,
    lastEmailedDate: emailedDate,
    customerName : customerName,
    customerEmail : customerEmail,
    statusMessage : "",
    statusDetail : statusDetail,
    deleted : false,
  }
}

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

function setQueryParamsEmail(searchQuery, sent) {
  searchQuery.IndexName = "company_id-email_sent-index";
  searchQuery.ExpressionAttributeValues[':value'] = {S: "no"};
  searchQuery.KeyConditionExpression += (" and email_sent" + (sent == "yes" ? "<" : "=") + " :value");
}

/*
Returns null if success or error message if any was found
*/
function setQueryParams(searchQuery, field, operator, value1, value2) {

  if (operator != "don't care" && value1.length == 0) {
    return "Error input fields cannot be empty";
  }
  if (operator == "between") {
    if (value2.length == 0) {
      return "Error for between second input field cannot be empty";
    }
  }

  let ddbFieldName = "order_id";
  if (field == "create time") {
    searchQuery.IndexName = "company_id-create_time-index";
    ddbFieldName = "create_time";
  } else if (field == "status") {
    searchQuery.IndexName = "company_id-delivery_status-index";
    ddbFieldName = "delivery_status";
  } else if (field == "tracking number") {
    searchQuery.IndexName = "company_id-tracking_num-index";
    ddbFieldName = "tracking_num";
  } else if (field == "email sent") {
    searchQuery.IndexName = "company_id-email_sent-index";
    ddbFieldName = "email_sent";
  }

  // now also check if value1 and value2 are convertible to times
  let t2Convert = true;
  if (ddbFieldName == "create_time") {
    let tzOffset = (new Date()).getTimezoneOffset() * 60000;
    let t1 = new Date((new Date(value1)).getTime() + tzOffset);
    if (!isValidDate(t1)) {
      return "Error " + value1 + " is an invalid date form"
    }

    // in this case, it is semantically same as between so changing operator
    if (operator == "=") {
      operator = "between";
      let t2 = new Date(t1.getTime() + 24 * 60 * 60000)
      value2 = t2.toISOString();
      t2Convert = false;
    }

    value1 = t1.toISOString();
  }

  if (operator != "don't care") {
    if (value1.length > 0) {
      searchQuery.ExpressionAttributeValues[':value1'] = {S: value1};
    }
    if (value2.length > 0) {
      if (ddbFieldName == "create_time") {
        let t2 = new Date(value2);
        if (t2Convert) {
          let tzOffset = (new Date()).getTimezoneOffset() * 60000;
          t2 = new Date(t2.getTime() + tzOffset);
        } 
        if (!isValidDate(t2)) {
          return "Error " + value2 + " is an invalid date form"
        }
        value2 = t2.toISOString();
      }
      searchQuery.ExpressionAttributeValues[':value2'] = {S: value2};
    }
  }

  // set the operator
  if (operator == "=") {
    searchQuery.KeyConditionExpression += " and " + ddbFieldName + "= :value1"
  } else if (operator == "<") {
    searchQuery.KeyConditionExpression += " and " + ddbFieldName + "< :value1"
  } else if (operator == "≤") {
    searchQuery.KeyConditionExpression += " and " + ddbFieldName + "<= :value1"
  } else if (operator == ">") {
    searchQuery.KeyConditionExpression += " and " + ddbFieldName + "> :value1"
  } else if (operator == "≥") {
    searchQuery.KeyConditionExpression += " and " + ddbFieldName + ">= :value1"
  } else if (operator == "begins with") {
    searchQuery.KeyConditionExpression += " and begins_with(" + ddbFieldName + ", :value1)"
  } else if (operator == "between") {
    searchQuery.KeyConditionExpression += " and " + ddbFieldName + " BETWEEN :value1 and :value2"
  } else if (operator == "don't care") {
    // don't add other operators
  } else {
    return "internal server error"
  }

  return null;
}

/* 
Assumes the following fields exist in t
  searchMode
  searchStatus
  searchParams
  searchList
  searchQuery
  searchPromise
*/
async function search(t) {
  t.searchMode = true;
  t.searchStatus = "LOADING";
  t.columnSort = "none";

  let companyName = await store.getters.getGroupNamePromise
  let ddb = await store.getters.getDdbPromise;
  let searchParams = t.searchParams;
  let searchQuery = {
      ExpressionAttributeValues: {
          ':company' : {S: companyName},
      },
      KeyConditionExpression: 'company_id = :company',
      TableName: 'd31-' + config.stage + '-packages',
      Limit: config.DbLoadGranularityRead,
      ScanIndexForward : t.searchParams.sortAsc,
  };

  let value1_split = searchParams.value1.split(/ |\t/).filter(function(e){return e});
  if (searchParams.field != "email sent" && searchParams.operator == "=" && value1_split.length > 1) {
    // in this case make the query such that there are several in =
    t.searchList = [];
    for (const i in value1_split) {
      if (i > 0 && (i % config.DbLoadGranularityRead == 0)) {
        await new Promise(resolve => setTimeout(resolve, 1000));
      }

      let _searchQuery = JSON.parse(JSON.stringify(searchQuery));
      let err = setQueryParams(_searchQuery, searchParams.field, "=", value1_split[i], "");
      if (err != null) {
        t.searchStatus = err;
        return;
      }

      // do search then push the results
      t.searchQuery = _searchQuery;
      t.searchPromise = ddb.query(_searchQuery).promise();

      let searchOrders = await t.searchPromise;
      let items = searchOrders.Items;
      let offset = searchOrders.length;
      for (const i in items) {
        t.searchList.push(itemToRow(items[i], offset + parseInt(i)));
      }
    }
    if (t.searchList.length == 0) {
      t.searchStatus = "NOT_FOUND";
    } else {
      t.searchStatus = "COMPLETE";
    }
  } else {
    // set the field
    let err = searchParams.field == "email sent" 
      ? setQueryParamsEmail(searchQuery, searchParams.emailSent)
      : setQueryParams(searchQuery, searchParams.field, searchParams.operator, 
        searchParams.value1, searchParams.value2);
    if (err != null) {
      t.searchStatus = err;
      return;
    }

    // do search then push the results
    t.searchQuery = searchQuery;
    t.searchPromise = ddb.query(searchQuery).promise();
    t.searchList = [];

    let searchOrders = await t.searchPromise;
    let items = searchOrders.Items;
    for (const i in items) {
      let idx = parseInt(i);
      t.searchList.push(itemToRow(items[i], idx));
    }

    if (items.length == 0) {
      t.searchStatus = "NOT_FOUND";
    } else if (!('LastEvaluatedKey' in searchOrders)) {          
      t.searchStatus = "COMPLETE";
    } else {
      t.searchStatus = "LOAD_MORE";
    }
  }
}

/* 
Assumes the following fields exist in t
  searchMode
  searchStatus
  searchParams
  searchList
  searchQuery
  searchPromise
  ordersQuery
  ordersPromise
  ordersList
*/
async function loadMore(t) {
  let status = t.searchMode ? t.searchStatus : t.status;
  if (status != "LOADING_ALL") {
    if (t.searchMode) {
      t.searchStatus = "LOADING";
    } else {
      t.status = "LOADING";
    }
  }
  t.columnSort = "none";

  let ddb = await store.getters.getDdbPromise;

  let ordersQuery = t.ordersQuery;
  let ordersPromise = t.ordersPromise;
  let ordersList = t.ordersList;
  if (t.searchMode) {
    ordersPromise = t.searchPromise;
    ordersQuery = t.searchQuery;
    ordersList = t.searchList;
  }

  let lastOrders = await ordersPromise;
  ordersQuery.ExclusiveStartKey = lastOrders.LastEvaluatedKey
  ordersPromise = ddb.query(ordersQuery).promise();

  let newOrders = await ordersPromise;
  let items = newOrders.Items;
  let offset = ordersList.length;
  for (const i in items) {
    ordersList.push(itemToRow(items[i], offset + parseInt(i)));
  }

  if (!('LastEvaluatedKey' in newOrders)) {          
    status = "COMPLETE";
  } else if (status != "LOADING_ALL") {
    status = "LOAD_MORE";
  }

  // now update all fields according to mode
  if (t.searchMode) {
    t.searchStatus = status;       
    t.searchQuery = ordersQuery;
    t.searchPromise = ordersPromise;
  } else {
    t.status = status;       
    t.ordersQuery = ordersQuery;
    t.ordersPromise = ordersPromise;
  }
}

function updateSearchParamPlaceholder(searchParams) {
  switch(searchParams.field) {
    case "order id" :
      searchParams.textPlaceholder = "order1";
      break;
    case "status" :
      searchParams.textPlaceholder = "delay";
      break;
    case "tracking number" :
      searchParams.textPlaceholder = "track1";
      break;
    case "create time" :
      searchParams.textPlaceholder = "2021-04-01";
      break;
  }
  if (searchParams.operator == "=") {
    searchParams.textPlaceholder += " ...";
  }
}

async function updateOrderInfoRequest(userSessionPromise, params) {
  var jwtToken = await userSessionPromise.then(session => {
    return session.credentials.idToken;
  })
  var ep = config.UpdateOrderInfoEndpoint;
  const headers = { 
    "Authorization": jwtToken
  };
  const orders = {
    'orders' : params
  }
  return axios.post(ep, orders, { headers })
    .then(response => { 
      return response.data;
    }).catch(err => {
      console.log("unable to send update request", err);
      return null;
    })
}

export default {
    init : init,
    deinit : deinit,
    itemToRow : itemToRow,
    utcTimeToLocal : utcTimeToLocal,
    setQueryParams : setQueryParams,
    search : search,
    loadMore : loadMore,
    updateSearchParamPlaceholder : updateSearchParamPlaceholder,
    updateOrderInfoRequest : updateOrderInfoRequest,
    timeDisplayOptions : timeDisplayOptions,
}
